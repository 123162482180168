.item[data-v-7be068dc] {
  border-bottom: 1px solid #E4EBF5;
  border-left: 1px solid #E4EBF5;
  border-right: 1px solid #E4EBF5;
  margin-top: -22px;
  padding-left: 20px;
}
.checkbox-item[data-v-7be068dc] {
  border-bottom: 1px solid #E4EBF5;
  border-left: 1px solid #E4EBF5;
  border-right: 1px solid #E4EBF5;
  margin-top: -22px;
  padding-left: 20px;
}
.radio[data-v-7be068dc] {
  border-bottom: 1px solid #E4EBF5;
  border-left: 1px solid #E4EBF5;
  border-right: 1px solid #E4EBF5;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  font-weight: 400;
}
.content[data-v-7be068dc] {
  margin-top: 20px;
}
.content .title[data-v-7be068dc] {
    font-size: 14px;
    color: #333333;
    letter-spacing: 0;
    font-weight: 600;
}
.warp[data-v-7be068dc] {
  position: relative;
}
.mask[data-v-7be068dc] {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  bottom: 0;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 999;
}
.header[data-v-7be068dc] {
  border-bottom: 2px solid;
  padding-bottom: 5px;
}
.info[data-v-7be068dc] {
  padding: 10px 0;
  border-bottom: 2px dotted;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  font-weight: 400;
}
.result[data-v-7be068dc] {
  padding: 10px 0 10px 30px;
  border-top: 2px dotted;
  border-bottom: 2px dotted;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  font-weight: 600;
}
.result div[data-v-7be068dc] {
    margin-top: 10px;
}
.bottom[data-v-7be068dc] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 20px;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  font-weight: 600;
  margin-left: 30px;
  margin-right: 30px;
}
.boder[data-v-7be068dc] {
  border-top: 1px solid #E4EBF5;
}
