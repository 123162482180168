
.excel-upload-input[data-v-12d4a639]{
  display: none;
  z-index: -9999;
}
.drop[data-v-12d4a639]{
  border: 2px dashed #bbb;
  width: 600px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}

